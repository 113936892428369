import { css } from 'styled-components';
import { ColorPalettes } from 'kinedu-react-components';

const { KineduPalette } = ColorPalettes;

export default {
  container: css`
    margin: auto;
    `,
  input: css`
      color: #979797;
      background-color: #fff;
      border: 1px solid ${KineduPalette.shadeMediumLight};
      box-shadow: none;

      width: 100%;
      height: 40px;
      padding: 10px;
      box-sizing: border-box;

      border-radius: 4px;

      font-size: 18px;
      font-weight: 300;
      text-align: left;
      letter-spacing: -0.36px;

      transition: all .20s ease-in-out;
      outline: none;

      ${({ theme }) => `
        &:focus {
          border: 1px solid ${theme?.colors?.primary};
          color: #333;
        }
      `}
    `,
  disabled: css`
      opacity: 0.5;
    `,
  placeholder: css`
      color: ${KineduPalette.shadeMedium};
    `,
  label: css`
    display: block;
    margin-bottom: 5px;

    color: #333;
    text-align: left;

    font-size: 1.4rem;
    font-weight: 500;
  `,
};
