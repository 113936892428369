import KineduAuth from 'kinedu-auth';
import qs from 'query-string';
import { useState } from 'react';
import { ENV, DAP_PATH, Environments, windowGlobal } from '../config';
import i18n from 'i18next';
import { t } from '../language/config';
import EventReporter, { getEventProviderInstance } from '../appEvents';
import { AppEvents, EventProviders } from '../constants/events';
import { mixpanel } from '../appEvents/providers';

const { API } = KineduAuth;
const {
  login,
  signup,
  googleAuth,
  sendPasswordResetEmail,
  resetPassword: putResetPassword,
} = API(ENV === Environments.LOCAL ? Environments.STAGE : ENV);

export const setLocalData = (user) => {
  const accountToken = user?.type === 'Teacher' ? user?.organization?.kinedu_classrooms_token?.access_token : user?.account?.kinedu_classrooms_token?.access_token;
  localStorage.setItem('user_id', user?.id);
  localStorage.setItem('account_token', accountToken);
  localStorage.setItem('active_center_token', user?.account?.kinedu_classrooms_token?.access_token);
  localStorage.setItem('account_type', user?.account?.type);
  localStorage.setItem('auth_token', user?.authentication_token);
  localStorage.setItem('user_email', user?.email);
  localStorage.setItem('user_type', user?.type);
  localStorage.setItem('user_confirmed', user?.confirmed);
  localStorage.setItem('user_locale', user?.locale);

  // If the user has organization object it means he is teacher
  let organizationId = user?.organization?.id;

  if (!organizationId) {
    organizationId = user?.account?.id;
  }

  localStorage.setItem('ORGANIZATION_ID', organizationId || '');
  localStorage.setItem('school_name', user?.account?.name);
  windowGlobal.location.assign(DAP_PATH);
};

const setSignupLocalData = (user) => {
  windowGlobal.localStorage.setItem('account_first_center_id', user?.account.centers[0].id);
  windowGlobal.localStorage.setItem('doing_ob', true);
  windowGlobal.localStorage.setItem('user_confirmed', false);
};

const mixpanelRegister = (user) => {
  /**
   * We should set the organization name after the user has logged in
   * to avoid assigning a wrong value if there was another session logged previously
   */
  let organizationName = user?.organization?.name;
  const isAccountTypeOrganization = user?.account?.type === 'Organization';

  if (!organizationName && isAccountTypeOrganization) {
    organizationName = user?.account?.name;
  }

  if (organizationName) {
    localStorage.setItem('ORGANIZATION_NAME', organizationName);
  }

  if (mixpanel.loadedInstance) {
    // Let's identify the user in MIXPANEL only
    getEventProviderInstance(EventProviders.MIXPANEL).identify(user?.id);

    EventReporter.setUserAttributes(user);

    EventReporter.action(AppEvents.LOGIN(), [
      EventProviders.MIXPANEL,
    ]);

    mixpanel.loadedInstance.register({
      organization_name: organizationName || '',
    });
  } else {
    console.log('🚫 user not being registered in MIXPANEL', 'background: #a20300; color: #ffa8a6; font-size: 16px');
  }
};

const useAuth = () => {
  const [errors, setErrors] = useState({});

  const onLogin = ({ email, password }) => {
    const loginData = qs.stringify({
      email,
      password,
    });

    return login(loginData)
      .then(({ user }) => {
        mixpanelRegister(user);
        setLocalData(user);
        return user;
      })
      .catch(err => {
        if (err.response) {
          if (err.response.data.error === 'Your account is locked.') {
          // if (lockedView) {/* unlockAccount({ email });*/}
            setErrors({
              generalError: 'Your account is locked.',
            });
          } else {
            const generalError = err.response.data.error || 'Something went wrong';
            const authError = err.response.data.error === 'Invalid %{authentication_keys} or password.' && t('ERROR_LOGIN');
            const emailError = err.response.data.email && err.response.data.email[0];
            const passError = err.response.data.password && err.response.data.password[0];
            console.log('%c🚫 err', 'background: #a20300; color: #ffa8a6; font-size: 16px', err.response);
            setErrors({
              emailError,
              passError,
              generalError: authError || generalError,
            });
          }
        }

        return new Error(err);
      });
  };

  const onSignup = ({ email, password, name = 'Organization name' }) => new Promise((resolve, reject) => {
    signup({
      user: {
        email,
        password,
        locale: i18n.language,
        organization_attributes: {
          name,
        },
      },
    })
      .then(({ user, ...rest }) => {
        // Let's identify the user in MIXPANEL only
        getEventProviderInstance(EventProviders.MIXPANEL).identify(user?.id);
        EventReporter.setUserAttributes(user);
        EventReporter.action(AppEvents.SIGNUP(), [EventProviders.MIXPANEL]);
        mixpanelRegister(user);
        setLocalData(user);
        setSignupLocalData(user);
        resolve({
          user,
          ...rest,
        });
        return user;
      })
      .catch(err => {
        let generalError = err.response.data.error || 'Something went wrong';
        let emailError = err.response.data.email ? err.response.data.email[0] : '';

        if (emailError === 'has already been taken') {
          emailError = 'ERROR_ALREADY_AN_ACCOUNT';
          generalError = '';
        }
        const passError = err.response.data.password ? err.response.data.password[0] : '';
        setErrors({
          emailError,
          passError,
          generalError,
        });
        console.log('%c🚫 err', 'background: #a20300; color: #ffa8a6; font-size: 16px', err.response.data);

        reject(err);
        return new Error(err);
      });
  });

  const sendResetPasswordEmail = (email) => new Promise((resolve, reject) => {
    sendPasswordResetEmail({
      user: { email },
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        console.log('%c🐛 err', 'background: #332167; color: #b3d1f6; font-size: 11px', err);
        const generalError = err.response.data.error || 'Something went wrong';

        setErrors({
          generalError,
        });
        reject(err);
      });
  });

  const resetPassword = (token, password, confirmPassword, view, schoolName) => {
    putResetPassword(token, password, confirmPassword).then(({ user }) => {
      setLocalData(user);
      localStorage.setItem(view, schoolName);
    }).catch(err => {
      localStorage.setItem(view, 'error');
      const generalError = err.response.data.reset_password_token[0] === 'is invalid' ? 'Invalid token' : 'Something went wrong';
      setErrors({
        generalError,
      });
    });
  };

  const onGoogleAuth = (accessToken, googleUser) => {
    googleAuth(accessToken).then(({ user }) => {
      setLocalData(user);
      if (user?.new_user) {
        setSignupLocalData(user);
      }
    });
  };

  return ({
    onLogin,
    onSignup,
    onGoogleAuth,
    errors,
    setErrors,
    sendResetPasswordEmail,
    resetPassword,
  });
};

export default useAuth;
